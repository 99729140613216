import React from 'react'
import MuiAlert from '@mui/material/Alert';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import {Divider, Box, Container, Grid,Snackbar,Button,Avatar,Autocomplete,InputAdornment,Stepper,Step,StepLabel,
    TextField,Typography,Input,MenuItem,Menu,Chip,Modal, Slide,} from "@mui/material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
} from '@mui/material';
const TwitterIcon = ()=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
                <path d="M18.3 2H22L14.9 10.6 23.3 22H16.9L11.7 15.1 5.3 22H2.3L9.9 12.8 2 2H8.6L13.4 8.3 18.3 2ZM17 20H18.8L7.2 4H5.2L17 20Z" />
        </svg>
    )
  }

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
export {
    Avatar,Divider,
    Box,
    Snackbar,
    MuiAlert,
    TextField,
    Button,
    Container,
    Grid,
    Typography,
    Input,Stepper,
    Step,StepLabel,
    MenuItem,
    Menu,InputAdornment,
    Chip,Autocomplete,
    Modal, Slide,
    PersonIcon,WorkIcon,BusinessCenterIcon,TelegramIcon,FacebookIcon,DeleteIcon,FilterListIcon,CheckBoxOutlineBlankIcon,
    CheckBoxIcon,TwitterIcon,LinkedInIcon,EmailIcon,InstagramIcon,MenuIcon,KeyboardArrowUpIcon,EditIcon,DoneIcon,
    CancelIcon,CheckCircleIcon,
    ChevronRightIcon,ArrowBackIcon,FaceIcon,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
}