import React, { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TelegramIcon,
  LinkedinIcon,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from "react-share";
import LinkIcon from "@mui/icons-material/Link";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";

const TwitterXIcon = ()=> {
  return (
    <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
                <path d="M18.3 2H22L14.9 10.6 23.3 22H16.9L11.7 15.1 5.3 22H2.3L9.9 12.8 2 2H8.6L13.4 8.3 18.3 2ZM17 20H18.8L7.2 4H5.2L17 20Z" />
        </svg>

  )
}

const SocialShare = ({ shareUrl, job }) => {
  const [copySuccess, setCopySucess] = useState(false);

  const onCopySuccess = () => {
    setCopySucess(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySucess(false);
  };
  const titleGen = (job) => {
    if (job) {
      const companyName = job?.company?.companyName;
      const jobTitle = job?.title;
      const title = companyName + " is hiring for " + jobTitle;
      return title;
    }
  };
  const jobTitle = titleGen(job);
  const summaryGen = (job) => {
    if (job) {
      const companyName = job?.company?.companyName;
      const jobTitle = job?.title;
      const jobDescription = job?.description;
      const summary =
        companyName + " is hiring for " + jobTitle + "\n" + jobDescription;
      // const summary=`${companyName} is hiring for "+${jobTitle}
      // ${jobDescription}`
      return summary;
    }
  };
  const jobSummary = summaryGen(job);
  const linkedinGen = (job) => {
    const linkedInPost = {
      "https://www.linkedin.com/shareArticle?mini": "true",
      source: "workCrew",
      title: jobTitle,
      //  summary:"jobSummary",
      url: "https://google.com",
    };
    return linkedInPost;
  };
  const linkedinPost = linkedinGen(job);
  // var queryString =

  const linkedinLinkGen = (data) => {
    return Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");
  };

  const queryString = linkedinLinkGen(linkedinPost);
  const linkedinSummary = linkedinGen(job);


  return (
    <>
      <Box
        className="socialMediaShare"
        sx={{
          margin: "0.5rem",
          display: "flex",
          gap: "0.5rem",
          flexWrap: "wrap",
        }}
      >
        <WhatsappShareButton url={shareUrl} title={jobTitle} separator={"\n"}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={shareUrl}
          // url="google.com"
          title={jobTitle}
          // summary={jobSummary}
          // source={shareUrl}
        >
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <FacebookShareButton
          url={shareUrl}
          quote={jobTitle}
          // url={"google.com"}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton title={jobTitle} url={shareUrl}>
          <TwitterXIcon />
        </TwitterShareButton>
        <EmailShareButton
          subject={jobTitle}
          body={jobSummary}
          separator={"\n"}
          url={shareUrl}
        >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <TelegramShareButton url={shareUrl} title={jobTitle}>
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <EmailShareButton
          onClick={() => {
            navigator.clipboard.writeText(shareUrl);
            // alert('Link Copied to Clipboard')
            onCopySuccess();
          }}
        >
          <LinkIcon />
        </EmailShareButton>
        {/* <div> {queryString}</div> */}
        <Snackbar
          open={copySuccess}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            sx={{ width: "100%" }}
            severity="success"
            variant="filled"
          >
            Successfully Copied to Clipboard
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default SocialShare;
